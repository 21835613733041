import React, { FC, useContext } from 'react';
import { Box, ResponsiveContext } from 'grommet';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { IImage } from '../utils/componentInterfaces';
import { Tag } from './tag';

const StyledAdvert = styled.div`
    position: relative;
    width: 100%;
    max-width: 1190px;
    margin: 0 auto;
    padding: 60px 30px;
    border-radius: 12px;

    img {
        border-radius: 12px;
    }
`;
interface Props {
    small?: IImage;
    large?: IImage;
    useCustom: boolean;
    link: any;
    tag?: string;
}

const Advert: FC<Props> = ({ small, large, useCustom, link, tag }) => {
    const size = useContext(ResponsiveContext);
    const {
        wp: {
            siteSettings: { globalBanner },
        },
    } = useStaticQuery(graphql`
        {
            wp {
                siteSettings {
                    globalBanner: siteOptions {
                        tag: promoBannerTag
                        link: promoBannerLink {
                            ... on WpPage {
                                id
                                uri
                                slug
                            }
                        }
                        large: promoBannerLarge {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 1195, quality: 90, layout: CONSTRAINED)
                                }
                            }
                        }
                        small: promoBannerSmall {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 390, quality: 90, layout: CONSTRAINED)
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <Box className="light-1" round="small">
            <StyledAdvert className="block">
                <div>
                    {useCustom ? (
                        <Link to={link.uri}>
                            {small && size === 'small' && (
                                <GatsbyImage
                                    image={small.localFile.childImageSharp?.gatsbyImageData}
                                    alt={small.altText || ''}
                                />
                            )}
                            {large && size !== 'small' && (
                                <GatsbyImage
                                    image={large.localFile.childImageSharp?.gatsbyImageData}
                                    alt={large.altText || ''}
                                />
                            )}
                        </Link>
                    ) : (
                        <Link to={globalBanner?.link?.uri}>
                            {tag && <Tag position="right">{tag}</Tag>}
                            {globalBanner?.small && size === 'small' && (
                                <GatsbyImage
                                    image={globalBanner?.small.localFile.childImageSharp?.gatsbyImageData}
                                    alt={globalBanner?.small.altText || ''}
                                />
                            )}
                            {globalBanner?.large && size !== 'small' && (
                                <GatsbyImage
                                    image={globalBanner?.large.localFile.childImageSharp?.gatsbyImageData}
                                    alt={globalBanner?.large.altText || ''}
                                />
                            )}
                        </Link>
                    )}
                </div>
            </StyledAdvert>
        </Box>
    );
};

export default Advert;
